export default {
  state: {
    menus: []
  },
  mutations: {
    SET_MENUS: (state, payload) => {
      state = payload;
    }
  },
  actions: {
    getMenus({ commit }) {

    }
  }
};
