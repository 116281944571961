<template>
  <div class="bar">
    <div class="bar-fix">
      <slot />
    </div>
    <div class="bar-place" />
  </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.bar{
  &-fix{
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    padding-top: 20px;
    background: #ebecf1;
    z-index: 9;
    padding-bottom: 10px;
    margin: 0 16px;
  }
  &-place{
    height: 40px;
  }
}
</style>
