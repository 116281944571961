function checkArray(key) {
  const arr = ['1', '2', '3', '4'];
  const index = arr.indexOf(key);
  if (index > -1) {
    return true; // 有权限
  }
  return false; // 无权限
}

const permission = {
  inserted(el, binding) {
    const permissionValue = binding.value; // 获取到 v-permission的值
    if (permissionValue) {
      const hasPermission = checkArray(permissionValue);
      if (!hasPermission) {
        // 没有权限 移除Dom元素
        // eslint-disable-next-line
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  }
};

export default permission;
