const registerDirective = (Vue) => {
  const files = require.context(
    // 指令目录
    './',
    // 不查找子目录
    false,
    // js文件
    /.+\.js$/
  );

  // 对配匹出来的的文件进行操作
  files.keys().forEach((fileName) => {
    // 获取指令函数
    const directiveConfig = files(fileName);

    // 获取指令名称
    const directiveName = fileName
    // 移除开始的 './'
      .replace(/^\.\//, '')
    // 移除文件扩展
      .replace(/\.\w+$/, '');

    // 避免当前文件注册指令
    if (directiveName === 'index') return;

    // 注册指令, 文件名作为指令名
    Vue.directive(directiveName, directiveConfig.default || directiveConfig);
  });
};

export default {
  install(Vue) {
    registerDirective(Vue);
  }
};
