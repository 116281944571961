import directives from '@/directives/index';
import filters from '@/filters/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Bar from '@/components/Layout/components/Bar.vue';
// import NProgress from 'nprogress';

const plugins = {
  install(Vue) {
    // 注册指令
    Vue.use(directives);
    // 注册过滤器
    Vue.use(filters);
    // 注册属性方法
    Vue.use(ElementUI);
    Vue.component('Bar', Bar);
  }
};

export default plugins;
