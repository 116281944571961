const getInput = (el) => {
  let inputDom = el;
  if (el.tagName !== 'INPUT') {
    const input = el.getElementsByTagName('input');
    if (input) {
      [inputDom] = input;
    } else {
      inputDom = undefined;
    }
  }
  return inputDom;
};

const bindEvent = (inputDom, binding) => () => {
  const reg = binding.value || /[^\w]/g;
  inputDom.value = (inputDom.value || '').replace(reg, '');
};

export default {
  inserted(el, binding) {
    const inputDom = getInput(el);
    inputDom.addEventListener('keyup', bindEvent(inputDom, binding));
  },
  unbind(el, binding) {
    const inputDom = getInput(el);
    inputDom.removeEventListener('keyup', bindEvent(inputDom, binding));
  }
};
