import router from '@/router';

const whiteList = [
  '/login',
  '/login/changePassword',
  '/assess/expertReport',
  '/admin/report'
]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in
  const hasToken = window.localStorage.getItem('token');
  const isWhiteList = whiteList.some((item) => to.path.includes(item));
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = true;
      if (hasRoles) {
        next();
      } else {
        try {
          // generate access routers
          const accessRoutes = [];

          // dynamically add accessible routes
          router.addRoutes(accessRoutes);

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          // remove token and go to login page to re-login
          window.localStorage.removeItem('authToken');
          // 错误提示弹窗
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else if (isWhiteList) {
    /* has no token and whiteList */
    next();
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    next(`/login?redirect=${to.path}`);
  }
});

router.afterEach(() => {
});
