const debounce = {
  inserted(el, binding) {
    let timer;
    el.addEventListener('keyup', () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        binding.value();
      }, 1000);
    });
  }
};
// <button v-debounce="debounceClick">防抖</button>
// methods: {
//   debounceClick () {
//     console.log('只触发一次')
//   }
// }
export default debounce;
