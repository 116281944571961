import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [

  {
    path: '/',
    redirect: '/home',
    component: () => import('@/components/Layout/index.vue'),
    children: [
      // 新页面
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/user/index.vue'),
      },
      {
        path: 'user/active',
        name: 'user.active',
        component: () => import('@/views/active/index.vue'),
      },
      {
        path: '/eval',
        component: () => import('@/views/eval/index.vue'),
        redirect: '/eval/editChildrenInfo',
        children: [
          {
            path: 'editChildrenInfo',
            name: 'eval.editChildrenInfo',
            component: () => import('@/views/eval/editChildrenInfo.vue'),
          },
          {
            path: 'question',
            name: 'eval.question',
            component: () => import('@/views/eval/question.vue'),
          },
          {
            path: 'report',
            name: 'eval.report',
            component: () => import('@/views/eval/report.vue'),
          },
        ]
      },
      // 原来页面
      {
        path: 'home/index',
        name: 'home.index',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'addUser',
        name: 'addUser',
        component: () => import('@/views/editUser/index.vue'),
      },
      {
        path: 'editUser/:id',
        name: 'editUser',
        component: () => import('@/views/editUser/index.vue'),
      },
      {
        path: '/assess',
        component: () => import('@/views/assess/index.vue'),
        redirect: '/assess/editChildrenInfo',
        children: [
          {
            path: 'editChildrenInfo',
            name: 'assess.editChildrenInfo',
            component: () => import('@/views/assess/editChildrenInfo.vue'),
          },
          {
            path: 'question',
            name: 'assess.question',
            component: () => import('@/views/assess/question.vue'),
          },
          {
            path: 'report',
            name: 'assess.report',
            component: () => import('@/views/assess/report.vue'),
          },
        ]
      },
      {
        path: '/assess',
        component: () => import('@/views/assess/expertIndex.vue'),
        children: [
          {
            path: 'expertReport',
            name: 'assess.expertReport',
            component: () => import('@/views/assess/expertReport.vue'),
          }
        ]
      },
    ]
  },
  {
    path: '/admin/report',
    name: 'admin.report',
    component: () => import('@/views/eval/report.vue'),
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    redirect: {
      name: 'login'
    },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/login/components/login.vue')
      },
      {
        path: 'changePassword',
        name: 'changePassword',
        component: () => import('@/views/login/components/changePassword.vue'),
      }
    ]
  },
];
const router = new VueRouter({
  routes
});

export default router;
