import Vue from 'vue';
import '@/style/index.less';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import registerPlugins from '@/registerPlugins';
import '@/permission';

Vue.config.productionTip = false;

Vue.config.devtools = true;

Vue.use(registerPlugins);

document.title = '儿童成长监测-机构端';

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
