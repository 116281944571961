const store = {
  namespaced: true,
  state: {
    titleChildName: '宝宝测评'
  },
  mutations: {
    SET_TITLE_CHILD_NAME: (state, data) => {
      state.titleChildName = data;
    },
  }
};

export default store;
