const registerHandler = (Vue) => {
  const files = require.context(
    // 指令目录
    './',
    // 不查找子目录
    false,
    // js文件
    /.+\.js$/
  );

  // 对配匹出来的的文件进行操作
  files.keys().forEach((fileName) => {
    // 获取函数
    const file = files(fileName);

    // 获取名称
    const keyName = fileName
    // 移除开始的 './'
      .replace(/^\.\//, '')
    // 移除文件扩展
      .replace(/\.\w+$/, '');

    // 避免当前文件注册
    if (keyName === 'index') return;

    // 注册, 文件名作为注册关键字
    Vue.filter(keyName, file.default || file);
  });
};

export default {
  install(Vue) {
    registerHandler(Vue);
  }
};
