const sliceStr = (str, length) => {
  const len = length || 50;
  if (!str) return '-';
  const tmp = str.slice(0, len);
  const prefix = str.length > len ? '...' : '';
  return tmp + prefix;
};

const subString = {
  inserted(el, binding) {
    const str = el.innerText;
    const len = binding.value || 10;
    el.innerText = sliceStr(str, len);
  }
};
export default subString;
