import Vue from 'vue';
import Vuex from 'vuex';
import permission from './permission';
import report from './module/report';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    permission,
    report
  }
});
